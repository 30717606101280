import { createContext } from 'react';
import type { TopErrorLike } from './ITopError';

/**
 * Shape of data in `TopErrorBoundaryAlertContext` context.
 */
export interface ITopErrorManagerContext {
  /**
   * Adds an error to the internal list of errors to be displayed.
   * @param error - The error to be added.
   */
  addError: (error: TopErrorLike) => void;
  /**
   * Removes an error from the internal list of errors to be displayed.
   * @param id - The ID of the error to be removed.
   */
  removeError: (id: string) => void;
}

export const TopErrorManagerContext = createContext<ITopErrorManagerContext>({
  addError: () => {
    // Do nothing.
  },
  removeError: () => {
    // Do nothing.
  }
});
TopErrorManagerContext.displayName = 'TopErrorManagerContext';
