'use client';

import { Region } from '@/constructs/Region';
import { Site } from '@/constructs/Site';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import I18NService, {
  ConfiguredLocaleString,
  Country
} from '@/services/isomorphic/I18NService';
import { JSPrimitive, NotKeys } from '@/type-utils';
import { DependencyList, useMemo } from 'react';
import { useLocale } from './useLocale';

/**
 * Represents an object that may not have any keys which would make it appears to be a
 * configuration object. For instance, if an object has the key `default`, `SANUK`, `NA`,
 * or `en-US`, it might appear to be a configuration object, even though it is not. We use
 * this to ensure that the generic values passed to the type `Config` are definitely not
 * mistakable for configuration objects.
 */
type NotConfig = NotKeys<keyof Config<any>> &
  NotKeys<keyof RegionalConfig<any>> &
  NotKeys<keyof LocaleConfig<any>> &
  object;

/**
 * Represents a value that can be used as a configuration value. This is can be any JS value
 * that is definitely not itself a configuration object.
 */
// eslint-disable-next-line @typescript-eslint/ban-types -- All functions are valid, including class declarations.
type ConfigurationValue = JSPrimitive | NotConfig | Function;

/**
 * Represents a configuration object that has a default value, and may have values for
 * specific brands, regions, and locales.
 * @example
 * const config: Config<string> = {
 *  default: 'default value',
 *  SANUK: 'sanuk value',
 *  UGG: {
 *    default: 'ugg default value',
 *    NA: 'ugg na value'
 *  },
 *  AHNU: {
 *    default: 'ahnu default value',
 *    NA: {
 *      default: 'ahnu na default value',
 *      US: 'ahnu na us value'
 *      'en-US': 'ahnu na en-us value'
 *    }
 *  }
 * }
 */
type Config<T extends ConfigurationValue> = {
  default: T;
} & {
  [key in Site]?: T | RegionalConfig<T>;
};

/**
 * Represents a configuration object that may have a default value, and may have values
 * for specific regions and locales.
 * @example
 * const config: RegionalConfig<string> = {
 *  default: 'default value',
 *  NA: 'na value',
 *  EMEA: {
 *    default: 'emea default value',
 *    GB: 'emea gb value'
 *    'en-GB': 'emea en-gb value'
 *  }
 * }
 */
type RegionalConfig<T> = {
  default?: T;
} & {
  [key in Region]?: T | LocaleConfig<T>;
};

/**
 * Represents a configuration object that may have a default value, and may have values
 * for specific locales and lang-locales.
 * @example
 * const config: LocaleConfig<string> = {
 *  default: 'default value',
 *  US: 'us value',
 *  'en-US': 'en-us value'
 *  'en-GB': 'en-gb value'
 *  'en-CA': 'en-ca value'
 * }
 */
type LocaleConfig<T> = {
  default?: T;
} & {
  [key in Country | ConfiguredLocaleString]?: T;
};

/**
 * Determines whether or not the supplied object is a regional configuration object. This
 * can be used to differentiate between a regional configuration object and a configuration
 * value because configuration values may not specify any keys that are also valid regions.
 * @param possibleConfig - The value to whether it is a regional configuration.
 * @returns `true` if the value is a regional configuration object, `false` otherwise.
 * @example
 * isRegionalConfig({ default: 'default value', NA: 'na value' }) // true
 * isRegionalConfig({ default: 'default value', US: 'us value' }) // false
 * isRegionalConfig({ default: 'default value', 'en-US': 'en-us value' }) // false
 * isRegionalConfig({ some: 'other', obj: 'ject' }) // false
 * isRegionalConfig(true) // false
 * isRegionalConfig('default value') // false
 */
const isRegionalConfig = <T extends ConfigurationValue>(
  possibleConfig: RegionalConfig<T> | ConfigurationValue
): possibleConfig is RegionalConfig<T> => {
  // If the value is not an object, or is falsy, it cannot be a regional configuration object.
  if (!(possibleConfig && possibleConfig instanceof Object)) return false;

  // If the value is truthy (i.e. not null), and either has a `default` key or has a key
  // that is also a valid region, then it is a regional configuration object.
  const isRegionalConfig =
    Object.hasOwn(possibleConfig, 'default') ||
    Object.keys(possibleConfig).some((key) => key in Region);

  // Finally, return the result.
  return isRegionalConfig;
};

/**
 * A RegEx that verifies that a string is lang-locale. Not necessarily a valid one, but
 * any combination of two lowercase characters, a hyphen, and two more uppercase
 * characters. Therefore the caveat is that this will match `en-US` but also will match a
 * nonsense locale like `ab-CD`. Albeit, that is a very unlikely scenario.
 */
const langLocaleRegex = /^[a-z]{2}-[A-Z]{2}$/;

/**
 * Determines whether or not the supplied object is a locale configuration object. This
 * can be used to differentiate between a locale configuration object and a configuration
 * value because configuration values may not specify any keys that are also valid locales
 * or lang-locales.
 * @param possibleConfig - The value to check for whether it is a locale configuration.
 * @returns `true` if the value is a locale configuration object, `false` otherwise.
 * @example
 * isLocaleConfig({ default: 'default value', US: 'us value' }) // true
 * isLocaleConfig({ default: 'default value', 'en-US': 'en-us value' }) // true
 * isLocaleConfig({ default: 'default value', NA: 'na value' }) // false
 * isLocaleConfig({ some: 'other', obj: 'ject' }) // false
 * isLocaleConfig(true) // false
 * isLocaleConfig('default value') // false
 */
const isLocaleConfig = <T extends ConfigurationValue>(
  possibleConfig: LocaleConfig<T> | ConfigurationValue
): possibleConfig is LocaleConfig<T> => {
  // If the value is not an object, or is falsy, it cannot be a locale configuration object.
  if (!(possibleConfig && possibleConfig instanceof Object)) return false;

  // If the value is truthy (i.e. not null), and either has a `default` key or has a key
  // that is also a valid locale or any string that appears to be an lang-locale, then it
  // is a regional configuration object.
  const isLocaleConfig =
    Object.hasOwn(possibleConfig, 'default') ||
    Object.keys(possibleConfig).some((key) => key in Country) ||
    Object.keys(possibleConfig).some((key) => langLocaleRegex.test(key));

  // Finally, return the result.
  return isLocaleConfig;
};

/**
 * A hook that returns the value of a configuration object that is most specific to the
 * current brand, region, and locale. This is useful for matching values that are
 * specific to a brand, region, or locale, particularly in the case of component styling.
 *
 * For instance, if we have a `Logo` component, we can use this hook to determine which
 * image to display based on the current brand (and optionally region, and/or locale).
 * However, this hook should **not** be used for localization or general site configuration;
 * please use the `lang` and `configs` libraries respectively to do so.
 *
 * Due to technical limitations, **the first generic type parameter, which represents**
 * **the hook's return type, must be explicitly provided**.
 *
 * For production builds, usages of this hook are statically optimized to remove values that
 * are not relevant to the current brand via the `pare-brandlocale-configs` participant.
 *
 * _**Tip**_: To optimize rendering performance for complex configuration objects
 * (e.g., those containing large JSX trees), favor returning function components rather
 * than JSX instances. However, be careful to not define function components inline, as a
 * rerender will cause it to be treated as a new component type and to reset internal state.
 *
 * @param configFactory - A factory function that returns the configuration object to retrieve the value from.
 * @param deps - A list of all reactive values referenced inside of the given configuration object.
 * @returns The value of the configuration object that is most specific to the current
 * brand, region, and locale.
 * @example
 * const iconClasses = useBrandLocaleValue<string>(() => ({
 *   default: `fa-solid ${props.classes}`,
 *   SANUK: {
 *     NA: {
 *       default: `fa-solid fa-sharp ${props.classes}`,
 *       US: `fa-light fa-sharp ${props.classes}`
 *     }
 *   }
 * }), [props.classes]);
 *
 * const brandLogo = useBrandLocaleValue<JSX.Element>(() => ({
 *   default: <DeckersLogo onClick={navigate} />,
 *   SANUK: {
 *     NA: <SanukNALogo size={size} />,
 *   },
 *   AHNU: <AhnuLogo size={size} />,
 * }), [navigate, size]);
 */
export const useBrandLocaleValue = <
  Value extends ConfigurationValue = never,
  ConfigParam extends Config<Value> = Config<Value>
>(
  configFactory: () => ConfigParam,
  deps: DependencyList
): Value => {
  // get the memoized config object if the explicit dependencies haven't changed
  const config = useMemo(configFactory, deps);

  /** The current brand / site the application was built for.
   * @example "UGG-COLLAB".
   */
  const currentBrand = (process.env.NEXT_PUBLIC_SITE_BRAND.toUpperCase()) as Site;
  /** The region that this page is being viewed in. */
  const { currentRegion } = I18NService;
  /** The locale that this page is being viewed in. */
  const currentLocale = useLocale();

  // Try to find the value most specific to the current brand, region, and locale.
  return useMemo(() => {
    /** Either the value specific to this brand, or a regional configuration object. */
    const brandValue = config[currentBrand];

    const hasRegionalConfig = isRegionalConfig(brandValue);

    /** Either the value specific to this region, or a locale configuration object. */
    const regionalValue = hasRegionalConfig
      ? // If the value is a regional config, see if it has a value for the current region.
        // If it does not have one, check for a default value.
        brandValue[currentRegion] ?? brandValue.default
      : undefined;

    const hasLocaleConfig = isLocaleConfig(regionalValue);

    /** The value specific to this lang-locale or locale. */
    const localeValue = hasLocaleConfig
      ? // If the value is a locale config, see if it has a value for the current
        // lang-locale (like "en-US"). If it does not have one, check for locale (like
        // "US"). Finally, if it does not have one, check for a default value.
        regionalValue[currentLocale.toString()] ??
        regionalValue[currentLocale.country] ??
        regionalValue.default
      : undefined;

    // If we found a locale-specific value, return it.
    if (localeValue !== undefined) return localeValue;

    // Otherwise, if we found a regional value, return it.
    if (regionalValue !== undefined && !hasLocaleConfig) return regionalValue;

    // Otherwise, if we found a brand value, return it.
    if (brandValue !== undefined && !hasRegionalConfig) return brandValue;

    // If no suitable value was found for this brand, region, or locale, return the default.
    return config.default;
  }, [
    config,
    currentBrand,
    currentRegion,
    currentLocale.language,
    currentLocale.country
  ]);
};
