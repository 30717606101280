import axios, { AxiosInstance } from 'axios';

import MemoryCache from '@/utils/MemoryCache';
import { encode } from 'querystring';
import {
  ReviewsServiceError,
  UnableToRetrieveProductRatingsError
} from './errors';

import Service from '../../Service';
import { DTOOfModel } from '../../models/Model';

import {
  ProductQuestionsModel,
  ReviewModel,
  ReviewsModel
} from '../../models/ReviewModel';
import { IRatings } from '../../models/ReviewsModel';
import type {
  IReviewsService,
  IReviewsServiceAuthorIdentity,
  ReviewsServiceCreateReviewObject,
  ReviewsServiceRetrieveReviewsOptions,
  ReviewsServiceVote
} from './IReviewsService';

/**
 * Client-side `ReviewsService`.
 * Sends requests to correct API routes to access to interact with the `ServerReviewsService`.
 */
export class ClientReviewsService extends Service implements IReviewsService {
  private client: AxiosInstance;
  private ratingCache = new MemoryCache<Promise<IRatings>>();
  private reviewsCache = new MemoryCache<Promise<ReviewsModel>>();

  /** Constructs a new `ReviewsService`. */
  public constructor() {
    super();

    // We're working with `/api/reviews` and `/api/questions`
    this.client = axios.create({
      baseURL: '/api'
    });
  }

  /** @inheritdoc */
  public async createReview(
    sku: string,
    review: ReviewsServiceCreateReviewObject,
    author: IReviewsServiceAuthorIdentity
  ): Promise<void> {
    // TODO: Clear the cache for this product's reviews, for all queries.

    await this.client.post(`/reviews/${sku}`, {
      review,
      author
    });
  }

  /** @inheritdoc */
  public async retrieveReviews(
    sku: string,
    options?: ReviewsServiceRetrieveReviewsOptions
  ): Promise<ReviewsModel> {
    const encoded = encode({
      ...options
    });
    const query = encoded.length > 0 ? `?${encoded}` : '';
    const path = `/reviews/${sku}${query}`;

    if (this.reviewsCache.has(path)) {
      return this.reviewsCache.get(path);
    }

    const responsePromise = this.client
      .get<DTOOfModel<ReviewsModel>>(`/reviews/${sku}${query}`)
      .then(({ data }) => ReviewsModel.from(data));

    this.reviewsCache.add(path, responsePromise);

    return responsePromise;
  }

  /** @inheritdoc */
  public async getReviewsForProduct(sku: string): Promise<ReviewsModel> {
    return this.retrieveReviews(sku);
  }

  /** @inheritdoc */
  public async voteOnReview(
    reviewID: ReviewModel['id'],
    vote: ReviewsServiceVote,
    shouldUndo = false
  ): Promise<void> {
    await this.client.post(`/reviews/vote`, {
      reviewID,
      vote,
      shouldUndo
    });
  }

  /** @inheritdoc */
  public async createQuestion(
    sku: string,
    question: string,
    author: IReviewsServiceAuthorIdentity
  ): Promise<void> {
    await this.client.post(`/questions/${sku}`, {
      question,
      author
    });
  }

  /** @inheritdoc */
  public async getQNAForProduct(sku: string): Promise<ProductQuestionsModel> {
    const response = await this.client
      .get<DTOOfModel<ProductQuestionsModel>>(`/questions/${sku}`)
      .catch((error) => {
        throw new ReviewsServiceError(
          `An unknown error occurred when trying to retrieve the Q&A for a product with sku: ${sku}.`,
          { cause: error }
        );
      });

    return ProductQuestionsModel.from(response.data);
  }

  /** @inheritdoc */
  public async createAnswer(
    questionID: number,
    answer: string,
    isPrivate?: boolean | undefined
  ): Promise<void> {
    await this.client.post(`/questions/create-answer`, {
      questionID,
      answer,
      isPrivate
    });
  }

  /** @inheritdoc */
  public async voteOnAnswer(
    answerID: number,
    vote: ReviewsServiceVote,
    shouldUndo = false
  ): Promise<void> {
    await this.client.post(`/questions/vote-on-answer`, {
      answerID,
      vote,
      shouldUndo
    });
  }

  /** @inheritdoc */
  public async getRatingForProduct(modelId: string): Promise<IRatings> {
    try {
      if (this.ratingCache.has(modelId)) {
        return await this.ratingCache.get(modelId);
      }

      const ratingPromise = this.client
        .get<{ ratings: IRatings }>(`/ratings/${modelId}`)
        .then(({ data }) => data.ratings);

      this.ratingCache.add(modelId, ratingPromise);

      return await ratingPromise;
    } catch (error) {
      throw new UnableToRetrieveProductRatingsError(
        `An unknown error occurred when trying to retrieve the ratings for a product with modelId: ${modelId}.`,
        { cause: error }
      );
    }
  }
}
