'use client';

import type {
  ILocale,
  ConfiguredLocaleString
} from '@/constructs/LocaleSchema';
import { useParams } from 'next/navigation';
import I18NService from '@/services/isomorphic/I18NService';

/**
 * A hook that gets the current lang locale from the useParams hook or
 * from a cached request. If used on the pages router
 * it should use the CurrentRequestService to get the locale.
 * @returns The current lang locale.
 * @throws An error if the langLocale cannot be constructed.
 */
export function useLocale(): ILocale {
  // Tries to get the params using the hook. This will be null if on the pages router
  // or in a place where the hook is unavailable.
  const params = useParams();

  if (params?.lang) {
    // If the lang is in the params get the lang locale from the string.
    return I18NService.getLocaleFromString(
      params.lang as ConfiguredLocaleString
    );
  }

  try {
    // If the lang is not in the params, try to get the locale from normal means.
    // This will be true for pages router and in the app router.
    return I18NService.currentLocale;
  } catch (error) {
    throw new Error(
      'Could not get the lang locale from the params or the cached request.'
    );
  }
}
