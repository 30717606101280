import { cache } from 'react';
import { Nullable } from '@/type-utils';
import { InvalidStateError, NotImplementedError } from '@/utils/errors';
import Service from '../../Service';

import { IPage, IProductListMetaData, PageType } from '../../models/Page';
import { EnvironmentService } from '../EnvironmentService';
import LoggerService from '../LoggerService';

/**
 * A page service that holds page meta data and is updated by the PageProvider.
 */
class PageService extends Service {
  private _page: Nullable<IPage>;

  private getCachedPage: (() => IPage) | undefined;

  /**
   * Gets the current page the user is on.
   * @returns A page object representing the current page.
   * @throws If there is an unknown error retrieving from cache.
   */
  public get page(): IPage {
    if (this._page) {
      return this._page;
    }

    // Cache is unavailable on the pages router, but is used to store
    // page data for server components.
    if (EnvironmentService.isCachableContext) {
      try {
        if (!this.getCachedPage) {
          this.getCachedPage = cache(() => this._page!);
        }

        const page = this.getCachedPage();

        if (!page) {
          throw new InvalidStateError(
            'Page data has not been stored. Do not retrieve it without storing it first.'
          );
        }

        return page;
      } catch (e) {
        if (e instanceof InvalidStateError) {
          LoggerService.error(e);
        }

        // If in a client component but the page is still not set, throw.
        LoggerService.error(
          new NotImplementedError(
            'The react Cache cannot be used outside of a Server Component.'
          )
        );
      }
    }

    LoggerService.warn(new InvalidStateError('Page data has not been stored.'));

    return { pageType: PageType.Unknown, url: '/unknown' };
  }

  /**
   * Sets the page on the page service. It represents what page the user is on.
   * It should only be set once per page load.
   */
  public set page(value: Nullable<IPage>) {
    this._page = value;

    if (!this.getCachedPage) {
      if (EnvironmentService.isCachableContext) {
        this.getCachedPage = cache(() => this._page!);
      }
    }
  }

  /**
   * This allows the editing of metadata on the client side. Search
   * page meta data is not available until the search is made on the client.
   * @param metaData - The meta data supplied with the page, eg: Coveo
   * product data that is correlated with product tiles.
   */
  public updateMetaData(metaData: IProductListMetaData): void {
    if (this._page) {
      if (!this._page.productListMetadata) {
        this._page.productListMetadata = { coveoData: {} };
      }
      // TODO: When more third party metadata is added,
      // create system for manipulating individual objects.
      this._page.productListMetadata.coveoData = metaData.coveoData;
    }
  }
}

export default new PageService();
