import { IAbortController } from '@/type-utils';
import { assertError, errorFactory } from './error-utils';

/** Error to use when an asynchronous action takes too long to resolve. */
export const { TimeoutError } = errorFactory('TimeoutError');

/**
 * Waits a number of milliseconds before continuing.
 * @param milliseconds - Number of milliseconds to wait before continuing.
 * @example ```ts
 * console.log(new Date()); // Now.
 * await wait(1000); // Wait one second.
 * console.log(new Date()); // A second later.
 * ```
 */
export const wait = (milliseconds: number): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, milliseconds);
  });

/**
 * Allows for setting a maximum execution time on a promise.
 * @param promise - The promise to set the timeout for.
 * @param milliseconds - The number of milliseconds after which the promise
 * is considered to be timed out.
 * @returns A promise which will either throw if the timeout occurs, or return
 * the expected value of the input promise.
 */
export const timeoutPromise = <T extends Promise<unknown>>(
  promise: T,
  milliseconds: number
): Promise<Awaited<T>> => {
  return new Promise<Awaited<T>>((resolve, reject) => {
    /**
     * This works because once a promise is resolved or rejected,
     * calling `resolve` or `reject` again has no effect.
     *
     * **Do not `await` the promises below, as they will not run in parallel then**.
     */
    promise.then(resolve as (_: unknown) => void).catch(reject);
    // eslint-disable-next-line promise/catch-or-return -- error handling is intentionally omitted here.
    wait(milliseconds).then(() =>
      reject(
        new TimeoutError('A promise took too long to resolve and timed out.')
      )
    );
  });
};

/**
 * Returns a promise that will wait for a specified time and then resolve
 * with a specified value. Useful to simulate the waiting times of asynchronous
 * calls on mocks.
 *
 * @param value - The value to resolve the promise with.
 * @param milliseconds - The number of milliseconds to wait before resolving.
 * @returns A promise that will resolve with `value` after `wait` milliseconds.
 *
 * @example
 * ```tsx
 * // Calling this function will return a promise that resolves with
 * // a test product after two seconds.
 * const mockProductFetch = () => waitPromise(testProduct, 2000);
 *
 * // On a React component:
 * const [product, setProduct] = useState<IProduct>();
 *
 * // On mount...
 * useEffect(() => {
 *    // Fetch the mock product
 *    mockProductFetch().then((p) => {
 *      // Save product to state
 *      setProduct(p);
 *    });
 * }, [])
 *
 * // This component will first render a spinner, and then show a
 * // product tile with the test product after two seconds.
 * return !product ? <Spinner /> : <ProductTile product={product} />
 *
 * ```
 */
export const waitPromise = async <T>(
  value: T,
  milliseconds: number
): Promise<T> => {
  await wait(milliseconds);
  return value;
};

/**
 * Filters an iterable or array-like using an asynchronous predicate.
 *
 * Note that the array order is maintained, but promises are evaluated **concurrently**.
 * @param items - The iterable to filter.
 * @param predicate - The predicate to use for filtering.
 * @returns A promise that resolves with an array of items that passed the predicate.
 */
export const filterAsync = async <T>(
  items: Iterable<T> | ArrayLike<T>,
  predicate: (item: T) => Promise<boolean>
): Promise<Array<T>> => {
  const arr = Array.isArray(items) ? (items as Array<T>) : Array.from<T>(items);
  const results = await Promise.all(
    arr.map(async (item) => ({
      item,
      include: await predicate(item)
    }))
  );

  return results.filter(({ include }) => include).map(({ item }) => item);
};

/**
 * Creates a new `AbortController` instance with a typed `signal` property.
 * @returns A new `AbortController` instance.
 */
export function createTypedAbortController<
  Reason = string
>(): IAbortController<Reason> {
  return new AbortController();
}

/**
 * Checks whether a value is a promise-like object (a.k.a a thenable).
 *
 * This function should only be used when explicitly dealing with an object that may
 * have a custom `.then` method implementation, such as a {@link StaleWhileRevalidate}.
 * For general promise checks, such as checking if the result of a function is async,
 * use `value instanceof Promise` instead.
 *
 * @param value - The value to check.
 * @returns Whether the value is a promise-like object.
 * @see {@link https://masteringjs.io/tutorials/fundamentals/thenable Thenables in JavaScript}
 */
export const isPromiseLike = <T = unknown>(
  value: unknown
): value is PromiseLike<T> =>
  typeof value === 'object' &&
  value !== null &&
  typeof (value as PromiseLike<T>).then === 'function';
// unfortunately, there is no way to check that the `then` method implements the correct signature.

/**
 * Collects all rejection reasons (errors) from an array of promises.
 * @param promises - An array of promises to collect errors from.
 * @returns An array of errors that occurred during the promises' execution.
 * If no errors occurred, the array will be empty.
 */
export async function collectAsyncErrors(
  promises: Array<Promise<unknown>>
): Promise<Array<Error>> {
  const errorPromises = promises.map((p) =>
    p.then(
      () => null,
      (e) => assertError(e)
    )
  );

  const results = await Promise.all(errorPromises);

  return results.filter((e) => e !== null);
}

/**
 * A utility which mimics the behavior of `Promise.withResolvers()`.
 * (We can't use `Promise.withResolvers()` directly because it's
 * not yet majorly supported).
 *
 * **Note**: Passing `resolve` its own promise will cause the promise to reject.
 *
 * @returns An object with a promise and its resolve and reject functions.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/withResolvers Promise.withResolvers() on MDN}
 */
export function withPromiseResolvers<T>(): PromiseWithResolvers<T> {
  let res: (value: T | PromiseLike<T>) => void;
  let rej: (reason: unknown) => void;
  const promise = new Promise<T>((resolve, reject) => {
    res = resolve;
    rej = reject;
  });

  return { promise, resolve: res!, reject: rej! };
}
